<template>
  <el-table-column :prop="prop" :width="width" :show-overflow-tooltip="tooltip" :align="align" :fixed="fixed" :sortable="sortable">
    <template #header>
      <el-tooltip :content="label" placement="top">
        <span>{{ label }}</span>
      </el-tooltip>
    </template>
    <template #default="scope">
      <slot :row="scope.row" :column="scope.column" :$index="scope.$index">
        {{ scope.row[prop] }}
      </slot>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "TableColumn",

  props: {
    // 标题：必传
    label: {
      type: String,
      required: true
    },
    // 绑定字段：必传
    prop: {
      type: String,
      required: true
    },
    // 单元格宽度
    width: {
      type: [Number,String]
    },
    // 超出单元格宽度是否显示...",默认：false
    tooltip: {
      type: Boolean,
      default: true // 可选值：false
    },
    // 文字显示：默认：left
    align: {
      type: String,
      default: "left" // 可选值：'left' 'right'
    },
    // 固定列
    fixed: {
      type: String
    },
    // 排序
    sortable:{
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  }
};
</script>