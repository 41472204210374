//根据二级域名区分是哪个客户，显示对应的图片和文字
import auth from "@/util/auth";
import {myI18n} from "@/lang";

export default {
  get() {
    let BASE_URL = process.env.BASE_URL;
    let host = document.location.hostname;
    if (host === "remo.eitaelevator.com.my") {
      return {
        name: "eita elevator",
        favicon: BASE_URL + "img/eita/favicon.ico",
        bigLogo: BASE_URL + "img/eita/logo_large.png",
        smallLogo: BASE_URL + "img/eita/logo_small.png",
        appDownload: BASE_URL + "img/eita/appQRCode.png",
      };
    }
    if (host === "xd.elevatorstar.com") {
      return {
        name: "现代电梯云",
        favicon: BASE_URL + "img/xiandai/favicon.ico",
        bigLogo: BASE_URL + "img/xiandai/logo_large.png",
        smallLogo: BASE_URL + "img/xiandai/logo_small.png",
        appDownload: BASE_URL + "img/xiandai/appQRCode.png",
      };
    }
    if (host === "sedt.syney.net") {
      return {
        name: "西尼电梯云",
        favicon: BASE_URL + "img/syney/favicon.ico",
        bigLogo: BASE_URL + "img/syney/logo_large.png",
        smallLogo: BASE_URL + "img/syney/logo_small.png",
        appDownload: BASE_URL + "img/syney/appQRCode.png",
      };
    }
    else {
      let favicon = auth.getUiSetting().faviconIcon;
      let logoLarge = auth.getUiSetting().logoLarge;
      let logoSmall = auth.getUiSetting().logoSmall;
      return {
        name: myI18n(window.config.siteNameEn || "G-Cloud电梯云", window.config.siteName || "G-Cloud电梯云"),
        favicon: favicon ? (window.config.SERVER_URL + favicon) : (BASE_URL + "img/favicon.ico"),
        bigLogo: logoLarge ? (window.config.SERVER_URL + logoLarge) : (BASE_URL + "img/logo_large.png"),
        cloud: BASE_URL + "img/cloud.png",
        smallLogo: logoSmall ? (window.config.SERVER_URL + logoSmall) : (BASE_URL + "img/logo60.jpg"),
        banners: [BASE_URL + "img/banner1.jpg", BASE_URL + "img/banner2.jpg"],
        appDownload: BASE_URL + "img/appQRCode.jpg",
      };
    }
  },
};
