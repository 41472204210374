import Vue from "vue";
import App from "./App.vue";
import i18n from "./lang";
import router from "./router";
import "./plugins/element.js";
import "./plugins/myPlugin.js";
import "./plugins/component.js";
import "normalize.css";
import store from "./store";
import "./style/main.scss";
import "./style/kanbanNum.scss";
import moment from "moment";
import echarts from "echarts";
// 引入组件(表头)
import TableColumn from "./components/TableColumn.vue";
// 全局注册组件
Vue.component("table-column", TableColumn);

Vue.prototype.$echarts = echarts;

import VForm from "vform-builds";
import "vform-builds/dist/VFormDesigner.css";
import "./style/fonts/dsfont.css";
import "./assets/iconfont/iconfont.css";

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.use(VForm);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
